import * as React from "react";
import { ContactDbWidget, Scope } from "../api/contacts/contacts.typings";
import { Select } from "@sgbs-ui/core";
import { mapToSearchCriteria } from "../api/contacts/contacts.mapper";
import { getContactsById, searchContacts } from "../api/contacts/contacts.api";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { SGMContactScope } from "../common/sgConnectScopes";
import { renderContact } from "../common/components/renderContact";
import { InvalidFeedback } from "../common/components/InvalidFeedback/InvalidFeedback";
import { CommonPickerProps, ContactDisplayOption, CreateLinkPickerProps } from "../common/typings";
import { useMultiSelectPicker } from "../common/hooks/useMultiSelectPicker";
import { useGenerateId } from "../common/hooks/useGenerateId";
import { isEmpty, map, orderBy } from "lodash-es";
import { useFetchGeneric } from "../common/hooks/useFetchGeneric";
import { useFetchSuggestions } from "../common/hooks/useFetchSuggestions";
import { useFetchHistory } from "../common/hooks/useHistory";
import { useGetContactIndicatorsQuery } from "../common/components/ContactQuality/Queries/ContactQuality.queries";
import { ApiRepository, ApiRequestConfig } from "@ic-anywhere/ic-dal";

const CONTACTS_SEARCH_FIELDS = ["name", "lastName", "fullName", "email", "givenName"];
const PICKER_NAME = "ic-contact-multi-picker";

export interface Props extends CommonPickerProps<ContactDbWidget[]>, CreateLinkPickerProps {
  isInternal?: boolean;
  onlyActive?: boolean;
  withQualityIndicators?: boolean;
  useHistory?: boolean;
  selectedIds?: string[];
  readonlyIds?: string[];
  displayOption: ContactDisplayOption;
  scope?: Scope;
  top?: boolean;
  suggestionIds?: string[];
  contactsIds?: string[];
  onClearSuggestions?: (items: ContactDbWidget[]) => void;
  onRemoveSuggestion?: (item: ContactDbWidget) => void;
}

export const ContactMultiSelectPicker: React.FC<Props> = ({
  selectedIds,
  readonlyIds,
  isInternal,
  onlyActive,
  withQualityIndicators = false,
  useHistory = false,
  maxResultCount,
  emitMode,
  scope,
  top = false,
  suggestionIds,
  contactsIds,
  displayOption,
  onReady,
  onChange,
  onClearSuggestions,
  onRemoveSuggestion,
  ...props
}: Props) => {
  const pickerId = useGenerateId(props.id);
  const fetch = useSgConnectFetch(SGMContactScope).fetch as FetchFn;

  const fetchByIds = React.useCallback(
    (ids: string[]): Promise<ContactDbWidget[]> => {
      return getContactsById(fetch, ids, undefined, false).catch(() => []);
    },
    [fetch]
  );

  const fetchContacts = React.useCallback(
    (term: string, repo: ApiRepository, signal: AbortSignal): Promise<ContactDbWidget[]> => {
      return searchContacts(repo, mapToSearchCriteria({ term, isInternal, onlyActive, maxResultCount, scope }), {
        signal,
      } as ApiRequestConfig);
    },
    [fetch, isInternal, onlyActive, maxResultCount, scope]
  );

  const fetchContactsByIds = React.useCallback(
    (ContactsIdsFromHistory?: string[]): Promise<ContactDbWidget[]> => {
      return getContactsById(fetch, contactsIds ?? ContactsIdsFromHistory ?? []);
    },
    [fetch, contactsIds]
  );

  const [selectedContacts, readOnlyContacts, onSelect] = useMultiSelectPicker(
    fetchByIds,
    emitMode,
    onChange,
    onReady,
    selectedIds,
    readonlyIds
  );

  const [contacts, isLoading, hasError, errorMessage, onTermChange, term] = useFetchGeneric<ContactDbWidget>(
    fetchContacts,
    fetchContactsByIds,
    CONTACTS_SEARCH_FIELDS,
    contactsIds
  );

  const { suggestions } = useFetchSuggestions<ContactDbWidget>(
    () =>
      getContactsById(fetch, suggestionIds ?? []).then(suggestions =>
        map(suggestions, suggestion => ({ ...suggestion, isSuggestion: true }))
      ),
    suggestionIds
  );

  const [history, setHistory, removeItemFromHistory, clearHistory] = useFetchHistory(
    fetchContactsByIds,
    PICKER_NAME,
    useHistory
  );

  const contactsToDisplay = isEmpty(contacts) && !term ? [...suggestions, ...history] : contacts;

  const { data: contactQuality } = useGetContactIndicatorsQuery(
    [...contactsToDisplay, ...selectedContacts, ...readOnlyContacts].map(c => c.id),
    withQualityIndicators && !isInternal
  );

  return (
    <>
      <Select.AsyncSelect<ContactDbWidget>
        id={pickerId}
        items={contactsToDisplay}
        selectedItems={orderBy(selectedContacts, ["name", "givenName"])}
        isSuggestionField="isSuggestion"
        readOnlyItems={readOnlyContacts}
        idField="id"
        labelField="fullName"
        iconName="person"
        errorMessage={errorMessage}
        placeholder={props.placeholder}
        inError={hasError || props.inError || !!errorMessage}
        onTermChange={onTermChange}
        onChange={items => {
          onTermChange("");
          setHistory(items?.map(item => item.id));
          onSelect(items);
        }}
        onClearSuggestions={(items: ContactDbWidget[]) => {
          clearHistory();
          onClearSuggestions?.(items);
        }}
        onRemoveSuggestion={(item: ContactDbWidget) => {
          removeItemFromHistory([item.id]);
          onRemoveSuggestion?.(item);
        }}
        createActionText={props.createActionText}
        onCreatClick={props.onCreateLinkClicked}
        showCreateAction={props.createLinkMode !== "none"}
        isLoading={isLoading}
        keepOrder={false}
        size={props.size}
        isOutline={props.outline}
        disabled={props.disabled}
        dropdownZIndex={1000}
        eraseSearchTermOnClick={true}
        top={top}
        noResultMessage={"No results found. Try another contact."}
      >
        {(item, props) =>
          renderContact(
            displayOption,
            contactQuality?.find(cq => cq.contact.id === item.id)
          )(item, props)
        }
      </Select.AsyncSelect>
      {props.inError && <InvalidFeedback errorMessage={props.errorMessage} />}
    </>
  );
};
